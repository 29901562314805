import { WindowLocation } from "@reach/router";
import { graphql } from "gatsby";
import React from "react";
import Card from "../../components/Card/Card";
import Text from "../../components/Text/Text";
import OneColumnLayout from "../../layouts/OneColumnLayout/OneColumnLayout";
import PageLayout from "../../layouts/PageLayout/PageLayout";
import { CommonContext } from "../../model/common";
import { GlobalMenus } from "../../model/menu";
import { PageMetadata } from "../../model/metadata";
import { SiteArea } from "../../utils/analytics";
import HexFlashTool from "./HexFlashTool";
import styles from "./HexFlashToolPage.module.scss";

export const pageQuery = graphql`
  query HexFlashToolPage($navLanguage: String) {
    menus: sanityGlobalConfig(language: { eq: $navLanguage }) {
      ...MenuData
    }
  }
`;

const HexFlashToolPage = ({
  pageContext,
  data: { menus },
  location
}: HexFlashToolPageProps) => {
  const title = "micro:bit WebUSB hex file flashing tool";
  const metadata: PageMetadata = {
    title,
    page: {
      _id: "hexFlashToolPage"
    } as any,
    alternates: []
  };
  return (
    <PageLayout
      siteArea={SiteArea.UNKNOWN}
      metadata={metadata}
      menus={menus}
      strings={pageContext.strings}
      location={location}
    >
      <OneColumnLayout
        title={title}
        social={false}
        main={
          <Card type="main" className={styles.root}>
            <Text variant="defaultLight">
              This tool uses WebUSB to flash (program) a hex file that you
              already have to a BBC micro:bit. It works in Google Chrome and
              Microsoft Edge browsers, and can be used instead of
              drag-and-dropping your hex file onto the MICROBIT drive.
            </Text>

            <HexFlashTool />

            <Text variant="defaultLight">
              Note: If your hex file was created with{" "}
              <a href="https://makecode.microbit.org">Microsoft MakeCode</a> or
              the <a href="https://python.microbit.org">Python Editor</a> we
              recommend that you open the hex file in those tools (in Google
              Chrome or Microsoft Edge) and use WebUSB to flash them from inside
              those editors. In MakeCode, choose "Connect Device" from the "..."
              menu to the right of the "Download" button. In the Python Editor,
              use "Send to micro:bit".
            </Text>
          </Card>
        }
      />
    </PageLayout>
  );
};

export default HexFlashToolPage;

interface HexFlashToolPageProps {
  data: {
    menus: GlobalMenus;
  };
  location: WindowLocation;
  pageContext: CommonContext;
}
