import {
  isUniversalHex,
  separateUniversalHex
} from "@microbit/microbit-universal-hex";
import classNames from "classnames";
import { DAPLink, WebUSB } from "dapjs";
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import Button from "../../components/Button/StandardButton/Button";
import Text from "../../components/Text/Text";
import VisuallyHidden from "../../components/VisuallyHidden/VisuallyHidden";
import styles from "./HexFlashTool.module.scss";

interface HexFlashToolProps {}

interface Status {
  state:
    | "tbd"
    | "unsupported"
    | "supported"
    | "selected"
    | "permission"
    | "in-progress"
    | "complete"
    | "error";
  message?: ReactNode;
}

interface HexFile {
  name: string;
  data: string;
}

const flash = async (
  file: HexFile,
  setStatus: (status: Status) => void,
  updateProgress: (progress: number) => void
) => {
  setStatus({ state: "permission", message: "Selecting device..." });

  try {
    const device = await navigator.usb.requestDevice({
      filters: [{ vendorId: 0x0d28, productId: 0x0204 }]
    });
    await flashDevice(file, device, setStatus, updateProgress);
    setStatus({ state: "complete", message: "Flash complete" });
  } catch (error) {
    const isNoDeviceSelected =
      error instanceof Error && /No device selected/.test(error.message);
    const unableToClaimInterface =
      error instanceof Error && /Unable to claim interface/.test(error.message);
    if (isNoDeviceSelected) {
      setStatus(supportedStatus());
    } else if (unableToClaimInterface) {
      setStatus({
        state: "error",
        message: (
          <span>
            Another process is connected to this device. Close any other tabs
            that may be using WebUSB (e.g. MakeCode, Python Editor), or unplug
            and replug the micro:bit before trying again.
          </span>
        )
      });
    } else {
      setStatus({ state: "error", message: (error as any).toString() });
    }
  }
};

const flashDevice = async (
  file: HexFile,
  device: USBDevice,
  setStatus: (status: Status) => void,
  updateProgress: (progress: number) => void
) => {
  const transport = new WebUSB(device);
  const target = new DAPLink(transport);

  // Detect micro:bit version and select the right Intel Hex for micro:bit V1 or V2
  if (!device.serialNumber) {
    throw new Error("Could not identify board version");
  }
  const microbitId = device.serialNumber.substring(0, 4);

  // If it is a Universal Hex, separate it, and pick the right one for the connected micro:bit version
  let hexStr = file.data;
  if (isUniversalHex(hexStr)) {
    let hexV1: string | null = null;
    let hexV2: string | null = null;
    let separatedBinaries = separateUniversalHex(hexStr);
    separatedBinaries.forEach(hexObj => {
      if (hexObj.boardId === 0x9900 || hexObj.boardId === 0x9901) {
        hexV1 = hexObj.hex;
      } else if (
        hexObj.boardId === 0x9903 ||
        hexObj.boardId === 0x9904 ||
        hexObj.boardId === 0x9905 ||
        hexObj.boardId === 0x9906
      ) {
        hexV2 = hexObj.hex;
      }
    });
    if (!hexV1 || !hexV2) {
      throw new Error("Could not find parts of universal hex");
    }
    if (microbitId === "9900" || microbitId === "9901") {
      hexStr = hexV1;
    } else if (
      microbitId === "9903" ||
      microbitId === "9904" ||
      microbitId === "9905" ||
      microbitId === "9906"
    ) {
      hexStr = hexV2;
    }
  }

  // Intel Hex is currently in ASCII, do a 1-to-1 conversion from chars to bytes
  const hexBuffer = new TextEncoder().encode(hexStr);

  const flashingMessage = "Flashing hex file...";
  target.on(DAPLink.EVENT_PROGRESS, updateProgress);

  setStatus({ state: "in-progress", message: flashingMessage });
  await target.connect();
  await target.flash(hexBuffer);

  setStatus({ state: "in-progress", message: "Disconnecting..." });
  await target.disconnect();

  setStatus({ state: "complete", message: "Flash sucessful!" });
};

const supportedStatus = (): Status =>
  navigator.usb
    ? {
        state: "supported",
        message: (
          <span>
            Drag and drop a hex file here
            <br /> or click to browse
          </span>
        )
      }
    : {
        state: "unsupported",
        message: (
          <span>
            WebUSB is not supported by this browser. Try using Google Chrome or
            Microsoft Edge.
          </span>
        )
      };

const HexFlashTool = (_: HexFlashToolProps) => {
  const [hexFile, setHexFile] = useState<HexFile | undefined>();
  const [status, setStatus] = useState<Status>({
    state: "tbd",
    message: "Checking WebUSB support..."
  });
  const progressRef = React.useRef<HTMLProgressElement>(null);

  const handleFlash = useCallback(
    async (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      if (!hexFile) {
        throw new Error();
      }
      const updateProgress = (progress: number) => {
        // Bypass React to avoid slowdown
        requestAnimationFrame(() => {
          if (progressRef.current) {
            progressRef.current.value = progress;
          }
        });
      };
      await flash(hexFile, setStatus, updateProgress);
    },
    [hexFile, progressRef]
  );

  const handleStartAgain = useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setStatus(supportedStatus);
  }, []);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length === 1) {
      const file = acceptedFiles[0];
      if (!file.name.toLowerCase().endsWith(".hex")) {
        setStatus({
          state: "error",
          message: "The chosen file was not a hex file"
        });
      } else {
        const reader = new FileReader();
        reader.onloadend = evt => {
          const hexStr = evt.target?.result;
          if (typeof hexStr === "string") {
            setHexFile({
              name: file.name,
              data: hexStr
            });
            setStatus({
              state: "selected",
              message: `File: ${file.name}`
            });
          }
        };
        reader.readAsText(file);
      }
    }
  }, []);

  useEffect(() => {
    // Deferred to now for SSR.
    if (status.state === "tbd") {
      setStatus(supportedStatus());
    }
  }, [status]);

  const zoneDisabled =
    status.state === "selected" || status.state === "unsupported";
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false,
    disabled: zoneDisabled
  });

  let content: ReactNode | null = null;
  switch (status.state) {
    case "selected":
    // Fallthrough
    case "permission": {
      content = (
        <Button
          primary
          onClick={handleFlash}
          disabled={status.state === "permission"}
        >
          Send to micro:bit
        </Button>
      );
      break;
    }
    case "complete":
    // Fallthrough
    case "error": {
      content = (
        <Button primary onClick={handleStartAgain}>
          Start again
        </Button>
      );
      break;
    }
    case "in-progress": {
      content = (
        <>
          <VisuallyHidden>
            <label htmlFor="progress">Flash progress</label>
          </VisuallyHidden>
          <progress id="progress" max={1} ref={progressRef} />
        </>
      );
      break;
    }
  }
  return (
    <div className={styles.root}>
      <div
        {...getRootProps()}
        className={classNames(styles.zone, !zoneDisabled && styles.enabled)}
      >
        <VisuallyHidden>
          <label htmlFor="file">File to send to the micro:bit</label>
        </VisuallyHidden>
        <input id="file" {...getInputProps()} />
        {status.message && (
          <div aria-live="polite">
            <Text className={styles.status} variant="subtitle">
              {status.message}
            </Text>
          </div>
        )}
        {content}
      </div>
    </div>
  );
};

export default HexFlashTool;
